import * as Types from '../../gql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetApplicationInitialStatusQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetApplicationInitialStatusQuery = { __typename?: 'Query', getApplicationInitialStatus: { __typename?: 'ApplicationInitialStatusResponseObject', object?: { __typename?: 'ApplicationInitialStatusObject', hasTicketing: boolean, hasActiveTicketing: boolean, hasArchivedTicketing: boolean, hasDonationForm: boolean, hasActiveDonationForm: boolean, hasArchivedDonationForm: boolean, firstForm?: { __typename?: 'FirstFormObject', id: string, path: string, formType: Types.FormType, formCategory: Types.FormCategory, formStatus: Types.FormStatus } | null, latestForm?: { __typename?: 'FrontendFormObject', id: string, formType: Types.FormType, formCategory: Types.FormCategory, isPrimaryCampaign?: boolean | null, isPrimaryTeam?: boolean | null, campaignId?: string | null, teamId?: string | null } | null } | null } };

export type OrganizationFormsAsMultiselectValuesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type OrganizationFormsAsMultiselectValuesQuery = { __typename?: 'Query', organizationFormsAsMultiselectValues: { __typename?: 'MultiselectValueObjecttvtFmuRy', items?: Array<{ __typename?: 'MultiselectValueObject', value: string, labels: Array<{ __typename?: 'MultiselectLabelObject', label: string, locale: Types.Locales }> }> | null } };

export type OrganizationOccurrencesAsMultiselectValuesQueryVariables = Types.Exact<{
  formIds?: Types.InputMaybe<Array<Types.Scalars['String']> | Types.Scalars['String']>;
}>;


export type OrganizationOccurrencesAsMultiselectValuesQuery = { __typename?: 'Query', organizationOccurrencesAsMultiselectValues: { __typename?: 'MultiselectOccurrenceValueObjectd44Hv0K7', items?: Array<{ __typename?: 'MultiselectOccurrenceValueObject', value: string, startUtc: GraphQL_Date, endUtc: GraphQL_Date, isArchived: boolean }> | null } };

export type OrganizationTagsAsMultiselectValuesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type OrganizationTagsAsMultiselectValuesQuery = { __typename?: 'Query', organizationTagsAsMultiselectValues: { __typename?: 'MultiselectValueResponseListObject', items?: Array<{ __typename?: 'MultiselectValueObject', value: string, labels: Array<{ __typename?: 'MultiselectLabelObject', label: string }> }> | null } };

export type DefaultQueryQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type DefaultQueryQuery = { __typename?: 'Query', defaultQuery: boolean };

export type TicketingRatesAsMultiselectValuesQueryVariables = Types.Exact<{
  formId?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type TicketingRatesAsMultiselectValuesQuery = { __typename?: 'Query', ticketingRatesAsMultiselectValues: { __typename?: 'MultiselectValueObjectllkqbmQz', items?: Array<{ __typename?: 'MultiselectValueObject', value: string, labels: Array<{ __typename?: 'MultiselectLabelObject', label: string, locale: Types.Locales }> }> | null } };


export const GetApplicationInitialStatusDocument = gql`
    query getApplicationInitialStatus {
  getApplicationInitialStatus {
    object {
      hasTicketing
      hasActiveTicketing
      hasArchivedTicketing
      hasDonationForm
      hasActiveDonationForm
      hasArchivedDonationForm
      firstForm {
        id
        path
        formType
        formCategory
        formStatus
      }
      latestForm {
        id
        formType
        formCategory
        isPrimaryCampaign
        isPrimaryTeam
        campaignId
        teamId
      }
    }
  }
}
    `;

/**
 * __useGetApplicationInitialStatusQuery__
 *
 * To run a query within a React component, call `useGetApplicationInitialStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApplicationInitialStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApplicationInitialStatusQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetApplicationInitialStatusQuery(baseOptions?: Apollo.QueryHookOptions<GetApplicationInitialStatusQuery, GetApplicationInitialStatusQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetApplicationInitialStatusQuery, GetApplicationInitialStatusQueryVariables>(GetApplicationInitialStatusDocument, options);
      }
export function useGetApplicationInitialStatusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetApplicationInitialStatusQuery, GetApplicationInitialStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetApplicationInitialStatusQuery, GetApplicationInitialStatusQueryVariables>(GetApplicationInitialStatusDocument, options);
        }
export type GetApplicationInitialStatusQueryHookResult = ReturnType<typeof useGetApplicationInitialStatusQuery>;
export type GetApplicationInitialStatusLazyQueryHookResult = ReturnType<typeof useGetApplicationInitialStatusLazyQuery>;
export type GetApplicationInitialStatusQueryResult = Apollo.QueryResult<GetApplicationInitialStatusQuery, GetApplicationInitialStatusQueryVariables>;
export const OrganizationFormsAsMultiselectValuesDocument = gql`
    query organizationFormsAsMultiselectValues {
  organizationFormsAsMultiselectValues {
    items {
      value
      labels {
        label
        locale
      }
    }
  }
}
    `;

/**
 * __useOrganizationFormsAsMultiselectValuesQuery__
 *
 * To run a query within a React component, call `useOrganizationFormsAsMultiselectValuesQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationFormsAsMultiselectValuesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationFormsAsMultiselectValuesQuery({
 *   variables: {
 *   },
 * });
 */
export function useOrganizationFormsAsMultiselectValuesQuery(baseOptions?: Apollo.QueryHookOptions<OrganizationFormsAsMultiselectValuesQuery, OrganizationFormsAsMultiselectValuesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrganizationFormsAsMultiselectValuesQuery, OrganizationFormsAsMultiselectValuesQueryVariables>(OrganizationFormsAsMultiselectValuesDocument, options);
      }
export function useOrganizationFormsAsMultiselectValuesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrganizationFormsAsMultiselectValuesQuery, OrganizationFormsAsMultiselectValuesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrganizationFormsAsMultiselectValuesQuery, OrganizationFormsAsMultiselectValuesQueryVariables>(OrganizationFormsAsMultiselectValuesDocument, options);
        }
export type OrganizationFormsAsMultiselectValuesQueryHookResult = ReturnType<typeof useOrganizationFormsAsMultiselectValuesQuery>;
export type OrganizationFormsAsMultiselectValuesLazyQueryHookResult = ReturnType<typeof useOrganizationFormsAsMultiselectValuesLazyQuery>;
export type OrganizationFormsAsMultiselectValuesQueryResult = Apollo.QueryResult<OrganizationFormsAsMultiselectValuesQuery, OrganizationFormsAsMultiselectValuesQueryVariables>;
export const OrganizationOccurrencesAsMultiselectValuesDocument = gql`
    query organizationOccurrencesAsMultiselectValues($formIds: [String!]) {
  organizationOccurrencesAsMultiselectValues(formIds: $formIds) {
    items {
      value
      startUtc
      endUtc
      isArchived
    }
  }
}
    `;

/**
 * __useOrganizationOccurrencesAsMultiselectValuesQuery__
 *
 * To run a query within a React component, call `useOrganizationOccurrencesAsMultiselectValuesQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationOccurrencesAsMultiselectValuesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationOccurrencesAsMultiselectValuesQuery({
 *   variables: {
 *      formIds: // value for 'formIds'
 *   },
 * });
 */
export function useOrganizationOccurrencesAsMultiselectValuesQuery(baseOptions?: Apollo.QueryHookOptions<OrganizationOccurrencesAsMultiselectValuesQuery, OrganizationOccurrencesAsMultiselectValuesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrganizationOccurrencesAsMultiselectValuesQuery, OrganizationOccurrencesAsMultiselectValuesQueryVariables>(OrganizationOccurrencesAsMultiselectValuesDocument, options);
      }
export function useOrganizationOccurrencesAsMultiselectValuesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrganizationOccurrencesAsMultiselectValuesQuery, OrganizationOccurrencesAsMultiselectValuesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrganizationOccurrencesAsMultiselectValuesQuery, OrganizationOccurrencesAsMultiselectValuesQueryVariables>(OrganizationOccurrencesAsMultiselectValuesDocument, options);
        }
export type OrganizationOccurrencesAsMultiselectValuesQueryHookResult = ReturnType<typeof useOrganizationOccurrencesAsMultiselectValuesQuery>;
export type OrganizationOccurrencesAsMultiselectValuesLazyQueryHookResult = ReturnType<typeof useOrganizationOccurrencesAsMultiselectValuesLazyQuery>;
export type OrganizationOccurrencesAsMultiselectValuesQueryResult = Apollo.QueryResult<OrganizationOccurrencesAsMultiselectValuesQuery, OrganizationOccurrencesAsMultiselectValuesQueryVariables>;
export const OrganizationTagsAsMultiselectValuesDocument = gql`
    query organizationTagsAsMultiselectValues {
  organizationTagsAsMultiselectValues {
    items {
      value
      labels {
        label
      }
    }
  }
}
    `;

/**
 * __useOrganizationTagsAsMultiselectValuesQuery__
 *
 * To run a query within a React component, call `useOrganizationTagsAsMultiselectValuesQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationTagsAsMultiselectValuesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationTagsAsMultiselectValuesQuery({
 *   variables: {
 *   },
 * });
 */
export function useOrganizationTagsAsMultiselectValuesQuery(baseOptions?: Apollo.QueryHookOptions<OrganizationTagsAsMultiselectValuesQuery, OrganizationTagsAsMultiselectValuesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrganizationTagsAsMultiselectValuesQuery, OrganizationTagsAsMultiselectValuesQueryVariables>(OrganizationTagsAsMultiselectValuesDocument, options);
      }
export function useOrganizationTagsAsMultiselectValuesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrganizationTagsAsMultiselectValuesQuery, OrganizationTagsAsMultiselectValuesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrganizationTagsAsMultiselectValuesQuery, OrganizationTagsAsMultiselectValuesQueryVariables>(OrganizationTagsAsMultiselectValuesDocument, options);
        }
export type OrganizationTagsAsMultiselectValuesQueryHookResult = ReturnType<typeof useOrganizationTagsAsMultiselectValuesQuery>;
export type OrganizationTagsAsMultiselectValuesLazyQueryHookResult = ReturnType<typeof useOrganizationTagsAsMultiselectValuesLazyQuery>;
export type OrganizationTagsAsMultiselectValuesQueryResult = Apollo.QueryResult<OrganizationTagsAsMultiselectValuesQuery, OrganizationTagsAsMultiselectValuesQueryVariables>;
export const DefaultQueryDocument = gql`
    query defaultQuery {
  defaultQuery
}
    `;

/**
 * __useDefaultQueryQuery__
 *
 * To run a query within a React component, call `useDefaultQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useDefaultQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDefaultQueryQuery({
 *   variables: {
 *   },
 * });
 */
export function useDefaultQueryQuery(baseOptions?: Apollo.QueryHookOptions<DefaultQueryQuery, DefaultQueryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DefaultQueryQuery, DefaultQueryQueryVariables>(DefaultQueryDocument, options);
      }
export function useDefaultQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DefaultQueryQuery, DefaultQueryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DefaultQueryQuery, DefaultQueryQueryVariables>(DefaultQueryDocument, options);
        }
export type DefaultQueryQueryHookResult = ReturnType<typeof useDefaultQueryQuery>;
export type DefaultQueryLazyQueryHookResult = ReturnType<typeof useDefaultQueryLazyQuery>;
export type DefaultQueryQueryResult = Apollo.QueryResult<DefaultQueryQuery, DefaultQueryQueryVariables>;
export const TicketingRatesAsMultiselectValuesDocument = gql`
    query ticketingRatesAsMultiselectValues($formId: String) {
  ticketingRatesAsMultiselectValues(formId: $formId) {
    items {
      value
      labels {
        label
        locale
      }
    }
  }
}
    `;

/**
 * __useTicketingRatesAsMultiselectValuesQuery__
 *
 * To run a query within a React component, call `useTicketingRatesAsMultiselectValuesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTicketingRatesAsMultiselectValuesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTicketingRatesAsMultiselectValuesQuery({
 *   variables: {
 *      formId: // value for 'formId'
 *   },
 * });
 */
export function useTicketingRatesAsMultiselectValuesQuery(baseOptions?: Apollo.QueryHookOptions<TicketingRatesAsMultiselectValuesQuery, TicketingRatesAsMultiselectValuesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TicketingRatesAsMultiselectValuesQuery, TicketingRatesAsMultiselectValuesQueryVariables>(TicketingRatesAsMultiselectValuesDocument, options);
      }
export function useTicketingRatesAsMultiselectValuesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TicketingRatesAsMultiselectValuesQuery, TicketingRatesAsMultiselectValuesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TicketingRatesAsMultiselectValuesQuery, TicketingRatesAsMultiselectValuesQueryVariables>(TicketingRatesAsMultiselectValuesDocument, options);
        }
export type TicketingRatesAsMultiselectValuesQueryHookResult = ReturnType<typeof useTicketingRatesAsMultiselectValuesQuery>;
export type TicketingRatesAsMultiselectValuesLazyQueryHookResult = ReturnType<typeof useTicketingRatesAsMultiselectValuesLazyQuery>;
export type TicketingRatesAsMultiselectValuesQueryResult = Apollo.QueryResult<TicketingRatesAsMultiselectValuesQuery, TicketingRatesAsMultiselectValuesQueryVariables>;